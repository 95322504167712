import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container } from '../../components/container'
import { Wrapper } from '../../components/wrapper';

export default () => {
  return (
    <Layout>

      <SEO title="News" />

      <Wrapper>

      </Wrapper>

    </Layout>
  );
}

// export const query = graphql`


// `
